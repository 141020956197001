import * as Sentry from '@sentry/sveltekit';
import { env } from '$env/dynamic/public';
import type { Handle } from '@sveltejs/kit';
import type { HandleClientError } from '@sveltejs/kit';

// If you don't want to use Session Replay, remove the `Replay` integration,
// `replaysSessionSampleRate` and `replaysOnErrorSampleRate` options.

if (env.PUBLIC_SENTRY_ENABLE === 'true') {
	Sentry.init({
		dsn: env.PUBLIC_SENTRY_DSN,
		environment: env.PUBLIC_ENVIRONMENT,
		tracesSampleRate: 1,
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1,
		integrations: [Sentry.replayIntegration()]
	});
}

export const handle: Handle = async ({ event, resolve }) => {
	return resolve(event);
};

export const handleError: HandleClientError = Sentry.handleErrorWithSentry(async ({ error }) => {
	const errorId = crypto.randomUUID();

	console.error(error, { extra: { errorId } });

	return {
		message: 'Whoops!',
		errorId
	};
});
