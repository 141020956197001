import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12')
];

export const server_loads = [2];

export const dictionary = {
		"/admin": [4,[2]],
		"/admin/app/checkout/[uuid]": [5,[2]],
		"/admin/app/dashboard": [6,[2]],
		"/admin/app/dashboard/[siteId]": [7,[2]],
		"/admin/app/page": [8,[2]],
		"/admin/public/article/[slug]": [9,[2]],
		"/admin/public/onboarding-form/[step]": [10,[2]],
		"/admin/public/user-sign/login": [11,[2]],
		"/admin/public/user-sign/register": [12,[2]],
		"/(website)/[...catchall]": [3]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';